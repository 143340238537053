import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import { Link } from 'gatsby'

import Topbar from '../components/topbar'
import Footer from '../components/footer'
import { APP_URI } from '../configs';

import { Dropdown, Checkbox } from "semantic-ui-react";

import SelectableList from "../components/selectableList";

import UserTopbar from "../components/userTopbar";

import UserContext from "../userContext";

import msj from '../images/msj-payment.svg'

import py1 from '../images/py1.svg'
import py2 from '../images/py2.svg'
import py3 from '../images/py3.svg'

import resume from '../images/payment_resume.svg'
import clips from '../images/clips-icon.svg'
import clipsYellow from '../images/clips-icon-yellow.svg'

import cup from '../images/cup-icon.svg'
import cupYellow from '../images/cup-icon-yellow.svg'

import checked from '../images/has-feature-icon.svg'
import noChecked from '../images/hasnt-feature-icon.svg'

import plan1 from '../images/plan-1.png'
import plan2 from '../images/plan-2.png'
import plan3 from '../images/plan-3.png'

import '../scss/upgrade.scss'

export default class upgrade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      following: false,
      checked: '-',
      errors: [],
      player: false,
      step: 0,
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  handleNext = (user) => {
    var newValue = this.state.step + 1;


    if (this.state.step === 6) {
      newValue = 0;
    }


    this.setState({
      checked: '-',
      step: newValue
    })
  }


  available_countries = () => {

    return [
      { key: '1', value: '1', text: "Argentina" },
      { key: '2', value: '2', text: 'Chile' },
      { key: '3', value: '3', text: 'Colombia' },
      { key: '4', value: '4', text: 'Ecuador' },
      { key: '5', value: '5', text: 'Peru' },
      { key: '6', value: '6', text: 'Uruguay' },
      { key: '7', value: '7', text: 'Paraguay' },
      { key: '8', value: '8', text: 'Bolivia' },
    ]
  }

  available_troopers = () => {

    return [
      { key: '1', value: '1', text: "Aydan_" },
      { key: '2', value: '2', text: 'r1vox' },
      { key: '3', value: '3', text: 'pixiee_97' },
      { key: '4', value: '4', text: 'tomi.gz' },
      { key: '5', value: '5', text: 'slaug-horse' },
      { key: '6', value: '6', text: 'lauti-casla' },
      { key: '7', value: '7', text: 'mike-retric' },
      { key: '8', value: '8', text: 'joseul_lol' },
    ]
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
  }

  handleBack = (e) => {
    if(this.state.step > 0){
      this.setState({step: (this.state.step - 1)})
    }
  }


  scrollToReference(ref) {
    const node = findDOMNode(ref.current);
    node.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }

  render() {
    return (
      <>
        {this.state.client &&
          <div className="upgrade">
            <UserContext.Consumer>
              {userObject => (
                <>
                  <div className="content troop-pro-content">
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>

                    </Helmet>

                    <div className="wrapper">
                      <section ref={this.references.hero} className="hero--section">
                        <div className="flex--container">
                          <h1 className="hero--title">Potenciate con PRO</h1>
                          <p className="hero--text">
                            Llevá tu Troop a otro nivel y disfrutá de los mejores beneficios de <span> ser un trooper PRO. </span>
                          </p>
                        </div>
                      </section>
                    </div>

                    <section ref={this.references.features} className="pricing--section">
                      <div className="wrapper">
                        <h2 className="pricing--title">¿Qué beneficios tenés con Troop PRO?</h2>
                        <div className="pricing--list">
                          <div className="pricing-item pricing-1">
                            <ol className="item--list">
                              <p className="pricing--type">
                                FREE
                              </p>
                              <li className="list--item title">
                                <div className="item--icon">
                                  <img src={clips} />
                                </div>
                                <p className="item--text title">
                                  Clips
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={noChecked} />
                                </div>
                                <p className="item--text">
                                  Edición ilimitada
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Transiciones estándar
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Calidad en 720p a 30fps
                                </p>
                              </li>
                              <li className="list--item title">
                                <div className="item--icon">
                                  <img src={cup} />
                                </div>
                                <p className="item--text title">
                                  Competencias
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Torneos de acceso público
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Insignias de desafíos en tu perfil
                                </p>
                              </li>
                              <li className="list--item title">
                                <p className="item--text title">
                                  Otros
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={noChecked} />
                                </div>
                                <p className="item--text">
                                  Plataforma libre de anuncios
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Fondos estándar para tu perfil
                                </p>
                              </li>

                            </ol>
                          </div>
                          <div className="pricing-item pricing-2">
                            <ol className="item--list">
                              <p className="pricing--type">
                                FREE
                              </p>
                              <li className="list--item title">
                                <div className="item--icon">
                                  <img src={clipsYellow} />
                                </div>
                                <p className="item--text title">
                                  Clips
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Edición ilimitada
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Transiciones especiales
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Calidad en 1080p a 60fps
                                </p>
                              </li>
                              <li className="list--item title">
                                <div className="item--icon">
                                  <img src={cupYellow} />
                                </div>
                                <p className="item--text title">
                                  Competencias
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Torneos exclusivos para Troop PRO
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Insignias PRO de desafíos en tu perfil
                                </p>
                              </li>
                              <li className="list--item title">
                                <p className="item--text title">
                                  Otros
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Plataforma libre de anuncios
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Suscripción a WTFast o Exit Lag (según la duración de tu plan)
                                </p>
                              </li>
                              <li className="list--item">
                                <div className="item--icon">
                                  <img src={checked} />
                                </div>
                                <p className="item--text">
                                  Fondos PRO para tu perfil
                                </p>
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </section>


                    <section ref={this.references.features} className="plans--section">
                      <div className="wrapper">
                        <h2 className="plans--title">Elegí el plan para vos</h2>
                        <div className="plans--list">
                          <div className="plans-item plan-1">
                            <ol className="item--list">
                              <h3 className="plan--title">Mensual</h3>
                              <img src={plan1} className="plan--image" />
                              <h3 className="plan--price">$100<span>/mes</span></h3>
                              <p onClick={() => this.setState({step: 1})}  className="button--global yellow">Hacete PRO</p>
                            </ol>
                          </div>
                          <div className="plans-item plan-2">
                            <ol className="item--list">
                              <p className="plan--type">
                                -20%
                              </p>
                              <h3 className="plan--title">Semestral</h3>
                              <img src={plan2} className="plan--image" />
                              <h3 className="plan--price">$80<span>/mes</span></h3>
                              <p onClick={() => this.setState({step: 1})} className="button--global yellow">Hacete PRO</p>
                            </ol>
                          </div>
                          <div className="plans-item plan-3">
                            <ol className="item--list">
                              <p className="plan--type">
                                -35%
                              </p>
                              <h3 className="plan--title">Anual</h3>
                              <img src={plan3} className="plan--image" />
                              <h3 className="plan--price">$65<span>/mes</span></h3>
                              <p onClick={() => this.setState({step: 1})} className="button--global yellow">Hacete PRO</p>
                            </ol>
                          </div>
                        </div>
                        <p className="checkout">
                          ¿Tenés dudas sobre los planes y cobros? <br />
                          Accedé a nuestro portal de <span><Link to={'/preguntas-frecuentes'}> Preguntas Frecuentes </Link></span>
                        </p>
                      </div>
                    </section>


                    {this.state.step > 0 &&
                      <section ref={this.references.features} className="signup--section">
                        <div className="wrapper">
                          <div className="signup--box">
                            <div className="back--container">
                              <p onClick={() => this.handleBack()}>Volver atras</p>
                              <div className="stepper">
                                <div className={this.state.step == 1 ? "step--circle active" : "step--circle"}>1</div>
                                <div className="step--line" />
                                <div className={this.state.step == 2 ? "step--circle active" : "step--circle"}>2</div>
                                <div className="step--line" />
                                <div className={this.state.step == 3 ? "step--circle active" : "step--circle"}>3</div>
                                <div className="step--line" />
                                <div className={this.state.step == 4 ? "step--circle active" : "step--circle"}>4</div>
                                <div className="step--line" />
                                <div className={this.state.step == 5 ? "step--circle active" : "step--circle"}>5</div>
                              </div>
                            </div>
                            {this.state.step === 1 &&
                              <div className="step step-1">
                                <h2 className="signup--title">Convertite en PRO</h2>
                                <h4 className="signup--subtitle">Seguí los pasos a continuación y empezá
                                  a disfrutar de ser un trooper PRO.</h4>
                                <p className="input--title">Elegí tu plan PRO:</p>
                                <div className="check--container">
                                  <div className="side-container">
                                    <Checkbox checked={this.state.checked === '$65'} onChange={() => this.state.checked === '$65' ? this.setState({ checked: '-' }) : this.setState({ checked: '$65' })} />
                                    <p> <span className="bold">Anual </span><span className="percent">-35%</span></p>
                                  </div>
                                  <p> <span className="bold">$65/mes</span></p>
                                </div>
                                <div className="check--container">
                                  <div className="side-container">
                                    <Checkbox checked={this.state.checked === '$80'} onChange={() => this.state.checked === '$80' ? this.setState({ checked: '-' }) : this.setState({ checked: '$80' })} />
                                    <p> <span className="bold">Semestral </span><span className="percent">-20%</span></p>
                                  </div>
                                  <p> <span className="bold">$80/mes</span></p>
                                </div>
                                <div className="check--container">
                                  <div className="side-container">
                                    <Checkbox checked={this.state.checked === '$100'} onChange={() => this.state.checked === '$100' ? this.setState({ checked: '-' }) : this.setState({ checked: '$100' })} />
                                    <p> <span className="bold">Mensual </span><span className="percent">-10%</span></p>
                                  </div>
                                  <p> <span className="bold">$100/mes</span></p>
                                </div>
                                <div className="total--container">
                                  <p>Total</p>
                                  <div className="price--container">
                                    <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="price" value={this.state.checked} placeholder="" /></div>
                                    <p>/MES</p>
                                  </div>
                                </div>
                              </div>
                            }
                            {this.state.step === 2 &&
                              <div className="step step-1">
                                <h2 className="signup--title">Muy bien, continuemos</h2>
                                <h4 className="signup--subtitle">Ya estás un paso más cerca de ser PRO.</h4>
                                <p className="input--title">Seleccioná tu método de pago:</p>
                                <div className="check--container">
                                  <div className="side-container">
                                    <Checkbox checked={this.state.checked === '$65'} onChange={() => this.state.checked === '$65' ? this.setState({ checked: '-' }) : this.setState({ checked: '$65' })} />
                                    <p><img src={py1}/> Tarjeta de crédito/débito</p>
                                  </div>
                                </div>
                                <div className="check--container">
                                  <div className="side-container">
                                    <Checkbox checked={this.state.checked === '$80'} onChange={() => this.state.checked === '$80' ? this.setState({ checked: '-' }) : this.setState({ checked: '$80' })} />
                                    <p><img src={py2}/> Mercado Pago</p>
                                  </div>
                                </div>
                                <div className="check--container">
                                  <div className="side-container">
                                    <Checkbox checked={this.state.checked === '$100'} onChange={() => this.state.checked === '$100' ? this.setState({ checked: '-' }) : this.setState({ checked: '$100' })} />
                                    <p><img src={py3}/> Efectivo / Transferencia</p>
                                  </div>
                                </div>
                              </div>
                            }
                            {this.state.step === 3 &&
                              <div className="step step-1">
                                <h2 className="signup--title">¡Ya casi, trooper!</h2>
                                <p className="signup--subtitle">Ingresá los datos a continuación para 
confirmar tu método de pago.</p>
                                <div className="input--group--container">
                                  <div>
                                    <p className="input--title">País *</p>
                                    <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="email" placeholder="" /></div>
                                    {this.state.errors.includes('email') && <div className="error--message"><p>Entrada invalida</p></div>}
                                  </div>
                                  <div>
                                    <p className="input--title">Provincia/Estado *</p>
                                    <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input type="text" placeholder="" /></div>
                                    {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                                  </div>
                                </div>
                                <div className="input--group--container">
                                  <div>
                                    <p className="input--title">Domicilio *</p>
                                    <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="email" placeholder="" /></div>
                                    {this.state.errors.includes('email') && <div className="error--message"><p>Entrada invalida</p></div>}
                                  </div>
                                  <div>
                                    <p className="input--title">Piso/Depto.</p>
                                    <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input type="text" placeholder="" /></div>
                                    {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                                  </div>
                                </div>
                                <p className="input--title">Ciudad</p>
                                <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input type="text" placeholder="" /></div>
                                {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                              </div>
                            }
                            {this.state.step === 4 &&
                              <div className="step step-1">
                                <h2 className="signup--title">Cada vez más cerca</h2>
                                <p className="signup--subtitle">Ingresá los datos de facturación que
                                  figuran a continuación. </p>
                                <p className="input--title">Número de tarjeta de crédito/débito *</p>
                                <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input type="text" placeholder="" /></div>
                                {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                                <div className="input--group--container">
                                  <div>
                                    <p className="input--title">Fecha de vencimiento *</p>
                                    <div className={`ui input ${this.state.errors.includes('email') ? 'error' : ''}`}><input type="email" placeholder="" /></div>
                                    {this.state.errors.includes('email') && <div className="error--message"><p>Entrada invalida</p></div>}
                                  </div>
                                  <div>
                                    <p className="input--title">Código de seguridad *</p>
                                    <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input type="text" placeholder="" /></div>
                                    {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                                  </div>
                                </div>
                                <p className="input--title">Nombre del titular de la tarjeta *</p>
                                <div className={`ui input ${this.state.errors.includes('username') ? 'error' : ''}`}><input type="text" placeholder="" /></div>
                                {this.state.errors.includes('username') && <div className="error--message"><p>Entrada invalida</p></div>}
                              </div>
                            }
                            {this.state.step === 5 &&
                            <div className="step step-1">
                            <h2 className="signup--title">¡Último paso!</h2>
                            <p className="signup--subtitle">Controlá que estén bien ingresados todos 
tus datos para convertirte en PRO.</p>
                            <img src={resume} />
                          </div>
                            }
                            {this.state.step === 6 &&
                              <div className="step step-1">
                                <h2 className="signup--title">¡A disfrutar de Troop PRO!</h2>
                                <img src={msj} />
                              </div>
                            }
                            <button className="button--global" onClick={() => this.handleNext(userObject)}>{this.state.step < 4 ? 'Siguiente' : (this.state.step == 4 ? 'Confirmar' :'Finalizar')}</button>
                          </div>
                        </div>
                      </section>
                    }

                    <Footer />
                  </div>
                </>
              )}
            </UserContext.Consumer>
          </div>
        }
      </>
    );
  }
}
